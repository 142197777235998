import NavLinks from './NavLinks';


const Navigation = () => {
    return(
        <div className='nave-items'>
        <nav className='desk-nav'>
        <NavLinks />
        </nav>            
        </div>

    );

}
export default Navigation;